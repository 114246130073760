<template>
    <div class="confirmation-page" dir="rtl">
        <h3 class="text-center font-bold mb-4">سياسة الخصوصية</h3>
        <div class="text-justify">
            <p>نحترم اهتمامكم فيما يتعلق بالخصوصية وقيمة علاقتنا معكم.</p>

            <p>سياسة الخصوصية هذه تصف أنواع المعلومات الشخصية التي نجمعها عن عملائنا، طريقة استخدامنا للمعلومات، مع من
                نشاركها، والخيارات المتوفرة لعملائنا بالنسبة لاستخدامنا للمعلومات.</p>

            <p>نحن نصف أيضاً المقاييس التي نتخذها لحماية أمن المعلومات وكيفية تواصل عملائنا معنا فيما يخص إجراءاتنا
                الخاصة.</p>


            <p>بتزويدك إيانا بمعلوماتك الشخصية، أنت تقبل بسياسة الخصوصية وتوافق على أنه يجوز لنا تجميع واستخدام
                المعلومات الشخصية والإفصاح عنها وفقًا لما هو وارد في سياسة الخصوصية. أنت غير ملزم بتقديم أي معلومات نطلب
                منك تقديمها. ومع ذلك، إذا لم تقدم لنا المعلومات التي نطلبها منك، ربما لا يمكننا أن نوفر لك أي سلع أو
                خدمات تعتمد على تجميع هذه المعلومات.</p>

            <p>يرجى الخروج من الموقع الإلكتروني على الفور إذا لم تقبل سياسة الخصوصية الحالية أو إذا كنت تقوم بزيارة
                الموقع من دولة أو إقليم يكون استخدام الموقع فيها محظورًا.</p>


            <h6 class="font-bold mt-5">البيانات التي يتم جمعها</h6>

            <p>في كل مرة تقوم فيها بزيارة مُختصر يتم إرسال المعلومات من المتصفح الى سجل ملفات الخادم مُختصر، حيث يتم
                حفظها هناك. تضم المعلومات: الوقت والتاريخ لزيارة الموقع، الصفحات التي قمت بعرضها، عنوان IP، العنوان
                الالكتروني <span dir="LTR">URL</span> الذي قادك الى مُختصر،
                المعلومات المخزنة ونوع المتصفح المستخدم.</p>

            <p>عندما نجمع كل هذه المعلومات، يتم حذف كافة عناوين IP أو تحويلها الى عناوين
                مجهولة الهوية. يعني تجهيل هوية العناوين هو تغيير عنوان IP الخاص بك بحيث لا
                يمكن ربطه مع المستخدم الأصلي أو لا يمكن ربطه الا من خلال اجراء بحوث واسعة النطاق.</p>

            <p>يتم استخدام المعلومات المجهولة والمخزنة في ملف سجل الخادم لتحسين تجربة التسوق على موقع مُختصر وذلك لجعل
                تصفح الموقع لدينا أكثر سهولة، وإيجاد الأخطاء بسرعة وتوجيه قدرات الخادم للتطوير وفق أهمية الحاجة اليها.
                على سبيل المثال، هذه المعلومات تسمح لنا بحساب أوقات ذروة الازدحام لدينا وبالتالي في وضع خطة لتقدير قدرة
                الخادم الكافية للحفاظ على تشغيل موقعنا بأسرع ما يمكن.</p>


            <h6 class="font-bold mt-5">المنافسات</h6>

            <p>نستخدم البيانات في حال إجراء أية مسابقات أو منافسات وذلك بغية إبلاغ الفائزين بها والإعلان عن العروض.
                ويمكنك العثور على المزيد من التفاصيل في حال الاطلاع على شروط المشاركة في المسابقة المعنيّة</p>

            <p>استخدام الإضافات الاجتماعية الخاصة بالفيسبوك</p>
            <p>يستخدم موقعنا الإضا فات الاجتماعية والتي توفرها شبكة التواصل الاجتماعي <span dir="LTR">plugins</span>
                والتي يتم تشغيلها من قبل facebook.com ويتم التعرف على هذه الإضافات عن طريق
                شعار الفيسبوك او عن طريق الاشعار Facebook Inc.، 1601 S. California Avenue ، Palo Alto، CA 94304 ، USA (&ldquo;Facebook&rdquo;):
                &ldquo;Facebook Social Plugin.developers.facebook.com/plugins.
            </p>

            <p>عندما تقوم بزيارة إحدى صفحات موقعنا والتي تحتوي على أحد الإضافات الاجتماعية، فسيعمل متصفحك على إنشاء رابط
                مباشر مع خوادم الفيسبوك، حيث تعمل فيسبوك على نقل محتويات الإضافات مباشرة الى متصفحك والذي بدوره يعمل على
                تضمين هذه المعلومات في الموقع مما يتيح لفيسبوك استقبال المعلومات المتعلقة بدخولك للصفحة المختارة من
                موقعنا. ولهذا السبب، فإنه يتم نقل عنوان الانترنت الخاص بالموقع IP الذي قمت
                بزيارته والذي يتضمن عنوان الى خادم فيسبوك المتواجد في الولايات المتحدة الأمريكية، حيث يتم تخزين
                البيانات. وتحدث هذه العملية حتى إذا كنت لا تملك حساباً على الفيسبوك أو إذا كنت لم تسجّل دخولك الى فيسبوك
                في ذلك الوقت.</p>

            <p>في حال سجّلت دخولك الى فيسبوك، فإنه يمكن تعيين زيارتك الى حسابك الخاص على فيسبوك. إذا قمت بالتفاعل مع أحد
                الإضافات الاجتماعية إذا قمت مثلا: بالضغط على &quot;أعجبني&quot; أو ادخلت أحد التعليقات، فسيتم نقل
                المعلومات المتعلقة بذلك مباشرة من متصفحك الى الفيسبوك وتخزينها من قبله، كما يمكن أن يتم نشر هذه
                المعلومات على الفيسبوك وأن تظهر لأصدقائك على الفيسبوك.</p>

            <p>إذا كنت من اعضاء الفيسبوك ولا ترغب بأن يقوم الفيسبوك بالربط ما بين البيانات المتعلقة بزيارتك لموقعنا مع
                البيانات الخاصة بعضويتك الخاص بمتصفحك مثل <span dir="LTR">add-ons</span> والمخزنة مسبقاً من قبل
                الفيسبوك، فنرجو منك تسجيل الخروج من فيسبوك قبل الدخول الى موقعنا</p>

            <p>ولمزيد من المعلومات عن حجم وهدف جمع البيانات التي يقوم بها فيسبوك والطريقة التي يتم فيها استخدام ومعالجة
                هذه البيانات، ولمعرفة المزيد عن حقوقك فيما يتعبك في هذا الأمر ومعرفة المزيد عن إعدادات الخيارات لحماية
                خصوصيتك، فنرجو منك زيارة <span dir="LTR">Facebook&rsquo;s privacy policy</span>.</p>


            <h6 class="font-bold mt-5">أمن المعلومات</h6>

            <p>كجزء من تسجيلك في هذا الموقع، سوف يطلب منك أن تقدم لنا بعض المعلومات الشخصية، مثل اسمك، عنوان الشحن،
                وعنوان البريد الإلكتروني ورقم الهاتف ومعلومات أخرى مماثلة وكذلك بعض المعلومات الإضافية الخاصة عنك مثل
                تاريخ الميلاد أو غيرها من المعلومات الشخصية. وسيتم تخزين المعلومات التي تقوم بإدخالها في نماذج قاعدة
                البيانات الخاصة بنا.</p>

            <p>بالإضافة إلى انه لنا الحق في حماية معلومات المستخدمين من الوصول غير المرخص لها، أو التغيير غير المصرح به،
                أو الكشف عنها، أو الغاء المعلومات التي نحتفظ بها.</p>

            <p>في حين أننا نحتفظ بالمعلومات الشخصية الخاصة بك، فقد نتصل بك بغرض التحقق من تحديث بياناتك الشخصية. نحن
                نشجعكم على تقديم البيانات الشخصية كاملة وكذلك يمكنك تبلغنا في أي وقت بأي تفاصيل غير صحيحة أو قديمة حتى
                نتمكن من تقديم أفضل خدمة ممكنة.</p>

            <p>نحن نشفر العديد من خدماتنا باستخدام طبقة المنافذ الآمنة SSL، فالمعلومات
                المالية مثل بطاقات الائتمان و/أو ارقام حسابات البنوك يتم نقلها باستخدام برتوكول طبقة المنافذ الآمنة
                SSL ليتم تشفيرها قبل إرسالها. نحن لا نقوم بتخزين معلومات بطاقات الائتمان لدينا بل
                نقدمها مباشرة إلى المؤسسة مالية المختصة بمعالجتها عبر اتصال مشفر.</p>


            <p>تخزين المعلومات</p>

            <p>نحن بقوم بتحمل المعلومات الشخصية الخاصة بك على خوادم آمنة ومحمية. فمن خلال تقديم المعلومات الشخصية الخاصة
                بك فأنت تقبل بنقل معلوماتك الشخصية وتخزينها على الخوادم الخاصة بنا. نحن نأخذ كل الاحتياطات للحفاظ على
                جميع المعلومات الشخصية الخاصة بك من الوصول غير المرخص لها أو استخدامها أو الإفصاح عنها.</p>

            <p>على الرغم من أننا نبذل قصارى جهدنا لحماية البيانات الخاصة بك، فنقل البيانات عبر الإنترنت ليس آمن تماما
                فلذلك لا يمكننا ضمان أمن البيانات المرسلة إلى موقعنا، وأي انتقال هو على مسؤوليتك الخاصة. بمجرد تلقينا
                البيانات الخاصة بك سوف نستخدم كل الإجراءات الصارمة وميزات الأمان في محاولة لمنع الوصول غير المرخص
                لها.</p>


            <h6 class="font-bold mt-5">البريد الإلكتروني</h6>

            <p>في بعض الأحيان قد نرسل معلومات متعلقة بالعروض الترويجية، والمعلومات التسويقية أو المناسبات، والتي نعتقد
                أنها قد تكون ذات فائدة لك. فانت لديك الخيار في أي وقت لوقف هذه الخدمة إذا كنت ترغب في ذلك، ويمكنك
                إلغاء الاشتراك من قائمة التسويق لدينا في أي وقت عن طريق النقر على رابط إلغاء الاشتراك داخل أي رسالة بريد
                الإلكتروني نرسلها لك. نحن لن نقوم أبدا بتوزيع عنوان بريدك الإلكتروني إلى أي طرف ثالث، قد نحتفظ بمحتوى أي
                البريد الإلكتروني ترسله لنا، إذا اخترنا للقيام بذلك.</p>


            <h6 class="font-bold mt-5">ملفات تعريف الارتباط (الكوكيز)</h6>

            <p>مثل معظم المواقع، يستخدم هذا الموقع ملفات نصية صغيرة تدعى ملفات تعريف الارتباط، او &quot;الكوكيز&quot;،
                لوضع متغير الجلسة على القرص الصلب لجهازك. ملف تعريف ارتباط الجلسة هذه يعرف متصفحك برقم عشوائي فريد، ولا
                يتم جمع أية معلومات شخصية من خلاله، ولكن يتيح لك استخدام ميزات الموقع وأن تفعل أشياء أخرى مثل بناء سلة
                التسوق الخاصة بك ولذلك فهو ضروري لعمل الموقع. عند إغلاق متصفح الويب، أو تظل غير نشط على الموقع لمدة ساعة
                يتم إتلاف ملف تعريف ارتباط الجلسة تلقائيا.</p>

            <p>وكثيرا ما تستخدم الكوكيز في المواقع، ويمكنك اختيار ما إذا وكيف سيتم قبول ملفات تعريف الارتباط عن طريق
                تكوين التفضيلات والخيارات في المتصفح الخاص بك. قد تكون قادرا على مشاهدة بعض صفحات المواقع حتى لو كنت لا
                تقبل ملفات تعريف الارتباط، ولكن سوف تحتاج إلى أن يكون متصفحك قادر على قبول ملفات تعريف الارتباط لإتمام
                عملية ارسال الطلب.</p>

            <p>تخدم هذا الموقع خدمة تحليلات جوجل (<span dir="LTR">Google</span>) وهي خدمة تقدّمها شركة جوجل لتحليل صفحات
                شبكة الانترنت. ولتحليل كيفية استخدام المستخدمين للموقع، ترتكز خدمة تحليلات جوجل على ملفات تعريف الارتباط
                وهي ملفات نصيّة وُضعت على حاسوبك. وستنقل شركة جوجل المعلومات التي تولّدها ملفات تعريف الارتباط عن
                استخدامك لهذا الموقع (بما في ذلك عنوان بروتوكول الإنترنت) إلى خوادم في الولايات المتحدة حيث سيتمّ
                تخزينها. وسوف تستخدم شركة جوجل هذه المعلومات لتقييم استخدامك للموقع، وإعداد تقارير لمشغلي الموقع عن
                نشاطه وتوفير خدمات أخرى مرتبطة بنشاط الموقع واستخدام الإنترنت. وقد تنقل جوجل أيضًا هذه المعلومات إلى طرف
                ثالث في حال طُلب منها ذلك بموجب القانون أو في حال عالج طرف ثالث المعلومات بالنيابة عن شركة جوجل. ولن
                تربط شركة جوجل عنوان بروتوكول الإنترنت خاصتكَ مع أيّة بيانات أخرى تحتفظ بها. ويمكنك رفض استخدام ملفات
                تعريف الارتباط وذلك عن طريق اختيار الإعدادات المناسبة على متصفحك ولكن ترجو أن تأخذ بعين الاعتبار أنّك
                عندئذ لن تستطيع الاستفادة من وظيفة الموقع الكاملة. وعبر استخدامك هذا الموقع، أنتَ توافق أن تعالج شركة
                جوجل بياناتك على النحو المبين والأغراض المفصّلة أعلاه.</p>


            <h6 class="font-bold mt-5">وصلات الموقع الخارجية</h6>

            <p>بعض الروابط لدينا قد تشير إلى مواقع ويب خارجية، نحن نوفر هذه الروابط فقط من اجل التيسير عليك. يرجى العلم
                بأننا لسنا مسؤولين عن ممارسات الخصوصية الخاصة بأي من المواقع الخارجية التي نشير إليها. تسري سياسة
                الخصوصية هذه فقط على المعلومات الشخصية التي نجمعها من موقعنا.</p>

            <p>نحن نشجعكم على قراءة بيانات الخصوصية لكل موقع ويب تزوره إذا كنت تنوي تقديم معلومات شخصية لهذا الموقع.</p>

            <p>بالإضافة إلى أننا نستخدم سياسة الاستهداف، والتي تتيح لنا عرض الاعلانات التي ترغب برؤيتها على موقعنا
                الالكتروني. نعني بالاستهداف أنه بإمكاننا الاعلان عن المنتجات التي قمت بعرضها مؤخرا على مواقع شركائنا
                وهذا يعني أن اعلاناتنا التي يتم عرضها بشكل خاص لك، حتى على مواقع الشركات الأخرى، هي العروض والاعلانات
                التي ترغب برؤيتها فقط. نفعل ذلك من خلال القيام بتحليل مفصل للمعلومات التي تم جمعها في ملفات تعريف
                الارتباط الخاصة بك على موقعنا لنتمكن من معرفة اهتماماتك كمستخدم. تتميز هذه المعلومات بانها مجهولة
                الهوية، حيث لا تحتوي على معلوماتك الشخصية أو اي بيانات لك في سجلات العملاء.</p>


            <h6 class="font-bold mt-5">الكشف عن معلومات</h6>

            <p>أي معلومات تطوعت بها لنا لن يتم الكشف عنها إلى أي طرف ثالث، وسيتم استخدامها فقط لأغراض خدمة العملاء
                الخاصة بنا. وكعميل لنا نضمن لك الأمان والموثوقية والكتمان.</p>

            <p>قد تقوم بطلب نسخة من البيانات الشخصية التي نحتفظ بها لك عن طريق البريد الإلكتروني. ولحمايتك قد نطلب منك
                إثبات هويتك قبل تقديم أي معلومات، ونحتفظ بالحق في رفض تقديم المعلومات المطلوبة إذا لم يتم تقديم الهوية
                التي ترضينا.</p>


            <h6 class="font-bold mt-5">التغييرات</h6>

            <p>نحن نحتفظ بالحق في إجراء التغييرات على سياسة الخصوصية هذه في أي وقت. نحن لن نقلل من حقوقك بموجب سياسة
                الخصوصية هذه بدون موافقة صريحة. سنقوم بنشر أية تغييرات على سياسة الخصوصية في هذه الصفحة، وإذا كانت
                التغييرات كبيرة سوف نقدم إشعارا أكثر وضوحا (بما في ذلك إشعارا بالبريد الإلكتروني بالتغييرات على سياسة
                الخصوصية). وسوف نحافظ أيضا على الإصدارات السابقة من سياسة الخصوصية هذه في الأرشيف للرجوع اليها. ونحن
                نشجعكم على مراجعة الموقع بانتظام لإعلان أي تعديلات على سياسة الخصوصية الخاصة بنا.</p>

            <h6 class="font-bold mt-5">إقرار</h6>

            <p>عندما تقوم بإرسال بيانات لنا أو لوكلائنا أو باستخدام الموقع، فإنك توافق على احتفاظنا بهذه البيانات
                ومشاركتها واستخدامها في الإجراءات اللازمة على النحو المبين في سياسة الخصوصية هذه.</p>

            <p>بموجب هذا، فاني اوافق على تحليل واستخدام بيانات البروتوكول لتطوير وتحسين أداء متجرهم للتسوق
                الالكتروني.</p>


            <h5 class="font-bold mt-5">سياسة الخصوصية بشكل مفصل</h5>
            <h6 class="font-bold mt-5">حقوقك كمستخدم لخدماتنا</h6>

            <p>لديك الحق في الحصول على معلومات أو تصحيح أو حظر أو حذف بياناتك في أي وقت. يمكن إلغاء أي موافقة ممنوحة في
                أي وقت وقد تعترض جزئيًا على معالجة بياناتك، حتى لو لم تكن هناك حاجة إلى موافقة منك للمعالجة. يمكنك
                الاتصال بمسؤول حماية البيانات لدينا في أي وقت للحصول على مزيد من المعلومات حول قضايا الخصوصية. يمكن
                الوصول إلينا إما عن طريق البريد الإلكتروني <a href="mailto:info@muktasar.com">info@muktasar.com</a></p>

            <h6 class="font-bold mt-5">البيانات التي نجمعها عنك</h6>
            <p>من ناحية أخرى، يتم التقاط بياناتك لأنك ترسلها إلينا. قد تكون هذه، على سبيل المثال، بيانات تدخلها في نموذج
                الاتصال الخاص بنا. يتم جمع البيانات الأخرى تلقائيًا عند زيارة الموقع من خلال أنظمة تكنولوجيا المعلومات
                لدينا. هذه هي قبل كل البيانات الفنية (مثل متصفح الإنترنت أو نظام التشغيل أو وقت طلب الصفحة). يتم جمع هذه
                البيانات تلقائيًا بمجرد زيارتك لمنصتنا.</p>
            <ul>
                <li>معلومات التسجيل: عندما تقوم بالتسجيل في مختصر (لعرضنا المجاني أو الاشتراك المدفوع أو استخدام رمز)،
                    فإننا نجمع المعلومات الشخصية التي تقدمها لنا طواعية عند التسجيل من خلال جهاز الوصول الخاص بك.
                </li>
                <li>معرّف إعلانات الجوال: إذا لم يتم تعطيله من قبل المستخدم، فإننا نجمع معرّف إعلانات الجوال الذي يوفره
                    جهازك المحمول. إذا كنت لا تريد منا جمع معرّف إعلانات الجوال هذا، فيمكنك دائمًا تعطيله أو تغييره على
                    جهازك. يرجى الاطلاع على هذا الدليل لأجهزة دائرة الرقابة الداخلية و هذا الدليل لأجهزة الروبوت.
                </li>
                <li>ملفات تعريف الارتباط وسياسة ملفات تعريف الارتباط: لتوفير خدمة مختصر ولجعل عرضنا أكثر سهولة وفعالية
                    وأمانًا، نستخدم أيضًا ما يسمى ملفات تعريف الارتباط لجمع البيانات وتخزينها. ملفات تعريف الارتباط
                    عبارة عن حزم بيانات صغيرة يتم تخزينها على جهازك ولا تسبب أي ضرر. نقوم بتجميع ملفات تعريف الارتباط في
                    الفئات التالية:
                </li>
                <li>ملفات تعريف الارتباط التقنية أو الوظيفية الضرورية: تساعد في جعل موقع الويب قابلاً للاستخدام من خلال
                    تمكين الوظائف الأساسية مثل التنقل في الصفحة والوصول إلى المناطق الآمنة في الموقع. لا يمكن للموقع أن
                    يعمل بشكل صحيح بدون ملفات تعريف الارتباط هذه.
                </li>
                <li>ملفات تعريف الارتباط المفضلة: تمكّن موقع الويب من تذكر المعلومات التي تغير الطريقة التي يتصرف بها
                    موقع الويب أو يبدو، مثل لغتك المفضلة أو المنطقة التي تتواجد فيها.
                </li>
                <li>ملفات تعريف الارتباط الإحصائية: تساعد أصحاب مواقع الويب على فهم كيفية تفاعل الزوار مع مواقع الويب من
                    خلال جمع المعلومات والإبلاغ عنها بشكل مجهول.
                </li>
                <li>ملفات تعريف الارتباط التسويقية: تُستخدم لتتبع الزوار عبر مواقع الويب. الهدف هو عرض إعلانات ملائمة
                    وجذابة للمستخدم الفردي.
                </li>
                <li>خدمات التتبع: نستخدم خدمات التتبع لجمع معلومات حول سلوك زوار صفحاتنا. بهذه الطريقة يمكننا ضمان تجربة
                    مستخدم مثالية. تستخدم هذه الخدمات ملفات تعريف المستخدمين ذات الأسماء المستعارة بمساعدة ملفات تعريف
                    الارتباط، بحيث لا يمكن الاستدلال المباشر على الأشخاص. بالطبع يمكنك الاعتراض على إنشاء هذه الملفات
                    الشخصية في أي وقت. بالمناسبة، يمكن تكوين كل جهاز وصول بطريقة لا يمكن في الأساس إنشاء ملفات تعريف
                    ارتباط على الإطلاق، أو يمكن حذفها مباشرة بعد الجلسة.
                </li>
                <li>المكونات الإضافية الاجتماعية: تحتوي صفحاتنا على أزرار من شبكات اجتماعية مختلفة بحيث يمكنك التوصية
                    بعروضنا و / أو خدماتنا و / أو منتجاتنا للأشخاص المهتمين الآخرين. إذا قمت بالنقر فوق هذا الزر، فستكون
                    البيانات مثل عنوان IP الحالي الخاص بك والمتصفح ومعلومات جهاز الوصول (النوع ونظام التشغيل)، ودقة
                    الشاشة، إذا كان لديك مكونات إضافية مثبتة، هي المرجع (من حيث أتيت إلى الموقع) وعنوان URL الحالي لمشغل
                    الموقع.
                </li>
                <li>معلومات الدفع: إذا كنت تستخدم وصولنا المجاني، فسيتم جمع وتخزين أي من مراحل التجربة لدينا أو أي من
                    اشتراكاتنا أو اشتريت أي شيء من خلال الخدمة، ومعلومات بطاقة الائتمان وغيرها من المعلومات المالية التي
                    نحتاجها لمعالجة الدفع وتخزينها مع خدمة الدفع مزود. نحن أنفسنا نتلقى القليل جدًا من المعلومات من
                    مزودي الدفع عنك.
                </li>
                <li>البيانات الشخصية للأطفال: لسنا على علم بجمع البيانات الشخصية من الأطفال دون سن 16 عامًا؛ إذا كان
                    عمرك أقل من 16 عامًا، فيرجى عدم التسجيل في مختصر أو إرسال أي بيانات شخصية إلينا. إذا علمنا أننا
                    جمعنا بيانات شخصية من طفل أقل من 16 عامًا، فسنحذف هذه المعلومات في أسرع وقت ممكن. إذا كنت تعتقد أن
                    طفلًا أقل من 16 عامًا ربما قدم لنا بيانات شخصية، فيرجى الاتصال بنا على <a
                        href="mailto:info@muktasar.com">info@muktasar.com</a>.
                </li>
            </ul>

            <h5 class="font-bold mt-5">كيف نجمع بياناتك</h5>
            <h6 class="font-bold mt-5">نجمع بياناتك بثلاث طرق مختلفة:</h6>
            <ul>
                <li>آليًا عند زيارة موقعنا على الويب</li>
                <li>من خلال ملفات تعريف الارتباط</li>
                <li>من خلال مساهمتك الطوعية</li>
            </ul>

            <h6 class="font-bold mt-5">لماذا نستخدم بياناتك</h6>
            <p>نريد أن نقدم لك استخدامًا لا تشوبه شائبة لخدماتنا. لذلك، هناك حاجة إلى البيانات لإدارة مواقعنا
                الإلكترونية واستكشاف الأخطاء وإصلاحها. نقوم بنقل البيانات التي تم جمعها للمعالجة إلى الإدارات الداخلية
                المعنية وكذلك إلى الشركات الأخرى التابعة لـ مختصر إلى مقدمي الخدمات الخارجيين ومعالجي العقود وفقًا
                للأغراض المطلوبة. ومع ذلك، نظرًا لأننا نعمل باستمرار على تحسين أنفسنا ونقدم لك خدمات مُحسّنة، فإن
                البيانات في شكل مستعار (من خلال ملفات تعريف الاستخدام) ضرورية أيضًا للإعلان و / أو أبحاث السوق. بالطبع،
                يمكن أن يتناقض هذا في أي وقت.</p>

            <h6 class="font-bold mt-5">كيف نعمل مع الشركاء</h6>
            <p>في بعض الحالات، تتم معالجة بياناتك أيضًا بمساعدة مزودي الطرف الثالث للعديد من الخدمات عبر الإنترنت (يرجى
                الرجوع إلى سياسة الخصوصية الخاصة بنا بالتفصيل أدناه). ومع ذلك، يتم تنظيم ذلك حصريًا من خلال اتفاقيات
                معالجة البيانات وتكون المعالجة ملزمة بالتعليمات، بحيث نتحمل دائمًا مسؤولية المعالجة.</p>

            <h6 class="font-bold mt-5">استخدام البيانات الخاصة بك لأغراض أخرى</h6>
            <p>بالإضافة إلى الأغراض التي سبق ذكرها، فإننا نخضع أيضًا للمتطلبات القانونية. وهذه هي:</p>
            <ul>
                <li>أي طلب للحصول على معلومات (والإفصاح ذي الصلة) للجهات الحكومية</li>
                <li>أي طلب للحصول على معلومات (والإفصاح ذي الصلة) لأصحاب حقوق النشر</li>
                <li>من أجل التعرف على إساءة استخدام خدماتنا المقدمة</li>
                <li>حل المشاكل أو تجنبها</li>
            </ul>

            <h5 class="font-bold mt-5">سياسة الخصوصية الخاصة بنا بالتفصيل</h5>
            <h6 class="font-bold mt-5">الكيان العام والمسئول</h6>
            <p>يسعدنا أنك مهتم بمختصر. من أجل تزويدك بخدماتنا، نحتاج إلى معلومات معينة عنك (بما في ذلك معلومات التعريف
                الشخصية - المعلومات التي تحدد هويتك شخصيًا). توضح سياسة الخصوصية هذه المعلومات التي نجمعها عنك ولأي غرض
                ولأي غرض نستخدمها. كما يوضح أيضًا الحقوق التي تتمتع بها فيما يتعلق بعمليات معالجة البيانات التي تؤثر
                عليك.</p>
            <p>مختصر (المشار إليها فيما يلي باسم "مختصر" أو "نحن") تعمل على مواقع الإنترنت <a
                href="https://www.muktasar.com">https://www.muktasar.com</a> وكذلك عبر تطبيق الهاتف المحمول منصة للتعلم
                عبر الأجهزة المحمولة (يشار إليها فيما بعد بـ "المنصة").</p>
            <p>الهيئة المسؤولة هي الشخص الطبيعي أو الاعتباري الذي يقرر، بمفرده أو مع آخرين، أغراض ووسائل معالجة البيانات
                الشخصية (مثل الأسماء وعناوين البريد الإلكتروني وما إلى ذلك).</p>

            <h6 class="font-bold mt-5">إبطال الموافقة</h6>
            <p>لا يمكن إجراء العديد من عمليات معالجة البيانات إلا بموافقتك الصريحة. يمكنك إبطال الموافقة الحالية في أي
                وقت. رسالة غير رسمية بالبريد الإلكتروني إلى info@muktasar.com كافية. يتم تنفيذ قانونية معالجة البيانات
                حتى يظل الإلغاء غير متأثر بالإلغاء.</p>
            <h6 class="font-bold mt-5">الحق في تقديم شكوى إلى السلطة الإشرافية المختصة</h6>
            <p>في حالة حدوث انتهاكات لحماية البيانات، يحق لك، بصفتك الشخص المعني، الاستئناف أمام السلطة الإشرافية
                المختصة. السلطة الإشرافية المختصة بقضايا حماية البيانات هي مسؤول حماية البيانات بالولاية في الولاية
                الفيدرالية التي يقع مقر شركتنا فيها أو التي تقيم فيها.</p>

            <h6 class="font-bold mt-5">تشفير SSL أو TLS</h6>
            <p>نحن نستخدم SSL أو أمان الإنترنت لأسباب أمنية ولحماية نقل المحتوى الحساس، مثل الطلبات أو الطلبات التي
                ترسلها إلينا. تشفير TLS. يشار إلى الاتصال المشفر من خلال تبديل شريط عنوان المتصفح من "http: //" إلى
                "https: //" ورمز القفل في شريط المتصفح. إذا تم تمكين تشفير SSL أو TLS، فلن تتمكن الجهات الخارجية من
                قراءة البيانات التي ترسلها إلينا.</p>

            <h6 class="font-bold mt-5">المعلومات، التصحيح، الحذف، الحجب، إمكانية نقل البيانات</h6>
            <p>يحق لك في أي وقت طلب معلومات حول بياناتك الشخصية التي نقوم بمعالجتها مجانًا. على وجه الخصوص، يمكنك أن
                تطلب منا تقديم معلومات حول أغراض المعالجة، وفئة البيانات الشخصية، وفئات المستلمين الذين تم الكشف عن
                بياناتك لهم، وفترة الاحتفاظ المخطط لها، وحق التصحيح، والحذف، والحد من المعالجة أو المعارضة، تمرير حق
                الاستئناف، وأصل بياناتهم، إذا لم نجمعها من قبلنا، ووجود آلية صنع القرار بما في ذلك التنميط، وعند
                الاقتضاء، معلومات مفيدة حول التفاصيل الخاصة بك.</p>
            <p>يحق لك طلب التصحيح الفوري لبياناتك الشخصية غير الصحيحة أو غير الكاملة التي قمنا بتخزينها.</p>
            <p>لديك الحق في طلب حذف بياناتك الشخصية المخزنة من قبلنا، إلا في الحالات التي تكون فيها معالجة البيانات
                مطلوبة لممارسة الحق في حرية التعبير والمعلومات، للوفاء بالتزام قانوني، لأسباب للمصلحة العامة أو لتأكيد
                الدعاوى القانونية أو ممارستها أو الدفاع عنها.</p>
            <p>لديك الحق في المطالبة بتقييد معالجة بياناتك الشخصية، وبقدر ما تكون دقة البيانات موضع نزاع من جانبك،
                فالمعالجة غير قانونية، لكنك ترفض حذفها ولم نعد بحاجة إلى البيانات ولك البيانات لتأكيد الدعاوى القانونية
                أو ممارستها أو الدفاع عنها أو إذا كنت قد اعترضت على المعالجة.</p>
            <p>لديك الحق في تلقي معلوماتك الشخصية التي قدمتها إلينا بصيغة منظمة ومشتركة وقابلة للقراءة آليًا أو أن تطلب
                إرسالها إلى شخص آخر مسؤول.</p>

            <h6 class="font-bold mt-5">الحق في الاعتراض</h6>
            <p>إذا كنت تريد ممارسة حقك في الاعتراض، فيرجى إرسال بريد إلكتروني إلى <a href="mailto:info@muktasar.com">info@muktasar.com</a>.
            </p>

            <h5 class="font-bold mt-5">جمع البيانات واستخدامها</h5>
            <h6 class="font-bold mt-5">ملفات سجل الخادم</h6>
            <p>يقوم مزود النظام الأساسي لدينا بجمع المعلومات وتخزينها تلقائيًا في ما يسمى بملفات سجل الخادم، والتي
                ينقلها متصفحك إلينا تلقائيًا. هذه هي نوع المتصفح وإصدار المستعرض ونظام التشغيل المستخدم وعنوان URL
                المرجعي واسم المضيف لجهاز الكمبيوتر الذي يتم الوصول إليه ووقت طلب الخادم وعنوان IP.</p>
            <p>البيانات لأمن البيانات وتحليل الأخطاء فقط. لن يتم دمج هذه البيانات مع مصادر البيانات الأخرى.</p>
            <p>يتم حذف ملفات سجل الخادم تلقائيًا بعد أسبوعين.</p>

            <h6 class="font-bold mt-5">ملفات تعريف الارتباط وملفات تعريف الارتباط</h6>
            <p>نحن نستخدم ملفات تعريف الارتباط المزعومة. لا تضر ملفات تعريف الارتباط بجهاز الوصول الخاص بك ولا تحتوي على
                فيروسات. تعمل ملفات تعريف الارتباط على جعل عرضنا أكثر سهولة وفعالية وأمانًا. ملفات تعريف الارتباط هي
                ملفات نصية صغيرة يتم تخزينها على جهاز الوصول الخاص بك وتخزينها بواسطة متصفحك.</p>
            <p>يُطلق على معظم ملفات تعريف الارتباط التي نستخدمها اسم "ملفات تعريف ارتباط الجلسة". سيتم حذفها تلقائيًا في
                نهاية زيارتك. تظل ملفات تعريف الارتباط الأخرى مخزنة على جهازك حتى تقوم بحذفها. تسمح لنا ملفات تعريف
                الارتباط هذه بالتعرف على متصفحك في زيارتك القادمة.</p>
            <p>يمكنك ضبط متصفحك بحيث يتم إخطارك بإعداد ملفات تعريف الارتباط والسماح بملفات تعريف الارتباط فقط في حالات
                فردية، واستبعاد قبول ملفات تعريف الارتباط لحالات معينة أو بشكل عام، وتمكين الحذف التلقائي لملفات تعريف
                الارتباط عند إغلاق المتصفح. قد يؤدي تعطيل ملفات تعريف الارتباط إلى تقييد وظائف موقعنا.</p>
            <p>تتم معالجة ملفات تعريف الارتباط المطلوبة لتنفيذ عملية الاتصال الإلكتروني أو لتوفير وظائف معينة ترغب في
                استخدامها (مثل وظيفة سلة التسوق). نحن كمشغلي مواقع ويب لدينا مصلحة مشروعة في تخزين ملفات تعريف الارتباط
                لتوفير خدماتنا الخالية من الأخطاء الفنية والمحسّنة. إذا تم تخزين ملفات تعريف الارتباط الأخرى (مثل ملفات
                تعريف الارتباط لتحليل سلوك التصفح الخاص بك)، فسيتم التعامل معها بشكل منفصل في سياسة الخصوصية هذه.</p>

            <h6 class="font-bold mt-5">التسجيل في هذا الموقع</h6>
            <p>يمكنك التسجيل في منصتنا لاستخدام خدماتنا. نحن نستخدم البيانات المدخلة فقط لغرض استخدام العرض المعني الذي
                قمت بالتسجيل فيه. يجب تقديم المعلومات الإلزامية المطلوبة أثناء التسجيل، مثل الاسم وعنوان البريد
                الإلكتروني، وإلا فلن يمكن إكمال التسجيل.</p>

            <p>بالنسبة للتغييرات المهمة مثل نطاق العرض أو في حالة التغييرات الفنية، نستخدم عنوان البريد الإلكتروني
                المقدم عند التسجيل لإبلاغك بهذه الطريقة.</p>

            <p>سيتم تخزين البيانات التي تم جمعها أثناء التسجيل من قبلنا طالما أنك مسجل في خدماتنا وسيتم حذفها لاحقًا.
                تظل فترات الاحتفاظ القانونية غير متأثرة.</p>

            <h6 class="font-bold mt-5">التسجيل وتسجيل الدخول باستخدام Facebook Connect</h6>
            <p>بدلاً من التسجيل مباشرة على موقعنا، يمكنك التسجيل باستخدام Facebook Connect. مقدم هذه الخدمة هو Facebook
                Ireland Limited، 4 Grand Canal Square، Dublin 2، Ireland، الشركة الأم: Facebook Inc، 1 Hacker Way، Menlo
                Park، CA 94025، USA ("Facebook").
            </p>
            <p>إذا قررت التسجيل في Facebook Connect والنقر فوق الزر "Sign Up with Facebook"، فسيتم إعادة توجيهك
                تلقائيًا إلى منصة Facebook. هناك يمكنك تسجيل الدخول باستخدام بيانات الاستخدام الخاصة بك. هذا يربط ملفك
                الشخصي على Facebook بموقعنا أو خدماتنا. يتيح لنا هذا الرابط الوصول إلى بياناتك المخزنة على Facebook.
                هؤلاء هم قبل كل شيء:</p>
            <p>بيانات الجرد (مثل الأسماء والعناوين) وتفاصيل الاتصال (مثل البريد الإلكتروني وأرقام الهواتف) وبيانات
                الأحداث ("بيانات الحدث" هي بيانات يمكن أن نرسلها إلى Facebook على سبيل المثال عبر Facebook pixels عبر
                التطبيقات أو بطرق أخرى وتتعلق للأشخاص أو أفعالهم؛ تتضمن البيانات، على سبيل المثال، معلومات حول زيارات
                مواقع الويب والتفاعلات مع المحتوى والوظائف وعمليات تثبيت التطبيقات وشراء المنتجات وما إلى ذلك؛ تتم
                معالجة بيانات الحدث بغرض إنشاء مجموعات مستهدفة للمحتوى والمعلومات الإعلانية (الجماهير المخصصة)؛ لا تحتوي
                بيانات الحدث على المحتوى الفعلي (مثل التعليقات المكتوبة)، ولا توجد معلومات تسجيل دخول ولا معلومات اتصال
                (أي لا توجد أسماء أو عناوين بريد إلكتروني). سيتم حذف بيانات الحدث بواسطة Facebook بعد بحد أقصى
                عامين).</p>
            <p>تُستخدم هذه المعلومات لإعداد حسابك وتوفيره وتخصيصه كجزء من توفير خدمة تعاقدية. الأساس القانوني لذلك هو
                موافقتك (المادة 6 الفقرة 1 س 1 مضاءة أ. اللائحة العامة لحماية البيانات)، والوفاء بالعقد والاستفسارات
                السابقة للتعاقد (المادة 6 الفقرة 1 س 1، باللائحة العامة لحماية البيانات) والشرعية الفائدة (المادة 6
                الفقرة 1 س 1 مضاءة و. اللائحة العامة لحماية البيانات).</p>
            <p>جنبًا إلى جنب مع Facebook Ireland Ltd.، نحن مسؤولون بشكل مشترك عن جمع أو استلام "بيانات الحدث" التي
                يجمعها Facebook (ولكن ليس مزيدًا من المعالجة) باستخدام عملية تسجيل الدخول الأحادي على Facebook والتي يتم
                تنفيذها على موقعنا. العرض عبر الإنترنت أو كجزء من الإرسال للأغراض التالية: أ) عرض معلومات الإعلان عن
                المحتوى التي تتوافق مع الاهتمامات المفترضة للمستخدمين؛ ب) تسليم الرسائل التجارية والمعاملات (مثل مخاطبة
                المستخدمين عبر Facebook Messenger)؛ ج) تحسين توصيل الإعلانات وإضفاء الطابع الشخصي على الوظائف والمحتوى
                (مثل تحسين التعرف على المحتوى أو المعلومات الإعلانية التي يفترض أنها تتوافق مع مصالح المستخدمين). لقد
                أبرمنا اتفاقية خاصة مع Facebook ( "<a href="https://www.facebook.com/legal/controller_addendum?_rdr">ملحق
                    المراقب المالي</a>")، والتي تنظم بشكل خاص <a
                    href="https://www.facebook.com/legal/terms/data_security_terms?_rdr">إجراءات الأمان التي</a> يجب
                على Facebook مراعاتها والتي وافق فيها Facebook على الوفاء بحقوق موضوع البيانات (أي يمكن للمستخدمين، على
                سبيل المثال، توفير معلومات أو طلبات حذف مباشرة إلى Facebook). ملاحظة: إذا زودنا Facebook بقيم وتحليلات
                وتقارير محسوبة (مجمعة، أي أنهم لا يتلقون أي معلومات حول المستخدمين الفرديين وهم مجهولون بالنسبة لنا)،
                فإن هذه المعالجة لا تتم في إطار المسؤولية المشتركة، ولكن على أساس ملحق معالجة البيانات ( "<a
                    href="https://www.facebook.com/legal/terms/dataprocessing?_rdr">شروط معالجة البيانات</a>" ")، " <a
                    href="https://www.facebook.com/legal/terms/data_security_terms?_rdr">شروط أمان البيانات</a> وفيما
                يتعلق بالمعالجة في الولايات المتحدة على أساس البنود التعاقدية
                القياسية. حقوق المستخدمين (خاصة المعلومات والحذف والاعتراض والشكوى إلى السلطة الإشرافية المختصة) ليست
                مقيدة بالاتفاقيات المبرمة مع Facebook.</p>

            <p>لمزيد من المعلومات، راجع <a href="https://www.facebook.com/legal/terms/?_rdr">شروط استخدام</a> Facebook و
                <a href="https://www.facebook.com/about/privacy/?_rdr">سياسة خصوصية</a> Facebook . . يقدم Facebook خيار
                اعتراض عبر
                <a href="https://www.facebook.com/adpreferences/ad_settings/?entry_product=account_settings_menu">رابط
                    Opt-Out-Link هذا</a>.</p>


            <h6 class="font-bold mt-5">التسجيل وتسجيل الدخول باستخدام Google Connect</h6>
            <p>بدلاً من التسجيل / تسجيل الدخول المباشر على موقعنا، يمكنك أيضًا التسجيل عبر Google. مقدم هذه الخدمة هو
                Google Ireland Limited، Gordon House، Barrow Street، Dublin 4، Ireland، الشركة الأم: Google LLC، 1600
                Amphitheatre Parkway، Mountain View، California 94043 USA ("Google").</p>
            <p>إذا قررت التسجيل / تسجيل الدخول مع Google والنقر فوق الزر "تسجيل الدخول باستخدام Google"، فسيتم إعادة
                توجيهك تلقائيًا إلى منصة Google. هناك يمكنك تسجيل الدخول باستخدام بيانات الاستخدام الخاصة بك. سيؤدي هذا
                إلى ربط ملفك الشخصي في Google بموقعنا أو خدماتنا. يتيح لنا هذا الرابط الوصول إلى بياناتك المخزنة على
                Google. هؤلاء هم قبل كل شيء:</p>
            <p>الاسم الأول | اللقب | عنوان البريد الإلكتروني | اسم المستخدم | عنوان URL لملف تعريف Google | صورة مميزة
                تُستخدم هذه المعلومات لإعداد حساباتك وتوفيرها وتخصيصها.
            </p>
            <p>لمزيد من المعلومات، راجع <a
                href="https://policies-google-com.translate.goog/terms?_x_tr_sl=en&_x_tr_tl=ar&_x_tr_hl=en&_x_tr_pto=nui">شروط
                خدمة</a> Google و<a
                href="https://policies-google-com.translate.goog/privacy?_x_tr_sl=en&_x_tr_tl=ar&_x_tr_hl=en&_x_tr_pto=nui">سياسة
                خصوصية</a> Google.</p>

            <h5 class="font-bold mt-5">وسائل التواصل الاجتماعي</h5>
            <h6 class="font-bold mt-5">إضافات فيسبوك</h6>
            <p>على صفحاتنا، تم دمج المكونات الإضافية للشبكة الاجتماعية Facebook، وموفر Facebook Ireland Limited، و 4
                Grand Canal Square، دبلن 2، أيرلندا، الشركة الأم: Facebook Inc، 1 Hacker Way، Menlo Park، CA 94025، USA
                ("Facebook"). يمكن التعرف على مكونات Facebook الإضافية من خلال شعار Facebook أو "Like-Button" ("أعجبني")
                على موقعنا. يمكن العثور على نظرة عامة حول مكونات Facebook الإضافية <a
                    href="https://developers.facebook.com/docs/plugins/">هنا</a>.</p>
            <p>عندما تزور منصتنا، يتم إنشاء اتصال مباشر بين متصفحك وخادم Facebook عبر المكون الإضافي. يتلقى Facebook
                المعلومات التي قمت بزيارتها على موقعنا بعنوان IP الخاص بك. إذا قمت بالنقر فوق "Like-Button" على Facebook
                أثناء تسجيل الدخول إلى حسابك على Facebook، فيمكنك ربط محتويات صفحاتنا بملفك الشخصي على Facebook. يسمح
                هذا لـ Facebook بربط زيارتك لصفحاتنا بحساب المستخدم الخاص بك. نشير إلى أننا كمزود للصفحات لسنا على دراية
                بمحتوى البيانات المرسلة واستخدامها من قبل Facebook. إذا كنت لا تريد أن يربط Facebook زيارة صفحاتنا بحساب
                مستخدم Facebook الخاص بك، فيرجى تسجيل الخروج من حساب مستخدم Facebook الخاص بك. يمكن العثور على مزيد من
                المعلومات في <a href="https://www.facebook.com/about/privacy/?_rdr">سياسة خصوصية</a> Facebook.</p>

            <h6 class="font-bold mt-5">لينكدين البرنامج المساعد</h6>
            <p>
                يستخدم موقعنا وظائف من شبكة LinkedIn. يتم تقديم الخدمة من قبل LinkedIn Corporation، 1000 West Maude Ave،
                Sunnyvale، CA 94085، الولايات المتحدة الأمريكية، والشركات التابعة لها LinkedIn Singapore Pte Ltd، و 10
                Marina Boulevard، و Marina Bay Financial Center Tower 2، Level 30، Singapore 018983 و LinkedIn Ireland
                Unlimited Company، ويلتون بليس، دبلن 2، أيرلندا (يُشار إليها جميعًا باسم "لينكد إن"). لقد أبرمنا ما يسمى
                بـ "اتفاقية معالجة البيانات" مع LinkedIn، حيث نلتزم LinkedIn بحماية بيانات عملائنا، وعدم الكشف عنها
                لأطراف ثالثة والامتثال لأحكام البنود التعاقدية القياسية.
                في كل مرة يتم فيها الوصول إلى إحدى صفحاتنا التي تحتوي على ميزات LinkedIn، ينشئ متصفحك اتصالاً مباشرًا
                بخوادم LinkedIn. يتم إعلام LinkedIn بأنك قمت بزيارة صفحات الويب الخاصة بنا من عنوان IP الخاص بك. إذا كنت
                تستخدم زر LinkedIn "توصية" وقمت بتسجيل الدخول إلى حساب LinkedIn الخاص بك، فمن الممكن لـ LinkedIn ربط
                زيارتك لموقعنا على الويب بحساب المستخدم الخاص بك. يمكن العثور على مزيد من المعلومات في <a
                href="https://ae.linkedin.com/legal/privacy-policy?">سياسة
                الخصوصية الخاصة بـ</a> LinkedIn.
            </p>

            <h6 class="font-bold mt-5">البرنامج المساعد لتويتر</h6>
            <p>على صفحاتنا وظائف خدمة تويتر المدرجة. تتوفر هذه الميزات من خلال Twitter Inc.، 1355 Market Street، Suite
                900، San Francisco، CA 94103 (الولايات المتحدة الأمريكية) بالإضافة إلى شركتها الفرعية Twitter
                International Company، One Cumberland Place، Fenian Street، Dublin، Ireland (معًا "Twitter"). باستخدام
                Twitter ووظيفة "إعادة التغريد"، يتم ربط مواقع الويب التي تزورها بحسابك على Twitter ومشاركتها مع مستخدمين
                آخرين. يتم نقل هذه البيانات أيضًا إلى Twitter. لمزيد من المعلومات، راجع <a
                    href="https://twitter.com/en/privacy">سياسة خصوصية</a> Twitter. يمكنك
                تغيير إعدادات الخصوصية الخاصة بك على Twitter في <a href="https://twitter.com/account/settings">إعدادات
                    حساب</a> Twitter.
                لقد أبرمنا ما يسمى بـ "اتفاقية معالجة البيانات" مع Twitter، حيث نلتزم Twitter بحماية بيانات عملائنا،
                وعدم الكشف عنها لأطراف ثالثة.
            </p>

            <h6 class="font-bold mt-5">المكوِّن الإضافي في Google+</h6>
            <p>تستخدم صفحاتنا ميزات Google. المزود هو Google Ireland Limited ("Google")، Gordon House، Barrow Street،
                Dublin 4، Irland.</p>
            <p>جمع المعلومات ومشاركتها: استخدم زر Google لنشر المعلومات في جميع أنحاء العالم. سيوفر لك زر Google
                وللمستخدمين الآخرين محتوى مخصصًا من Google وشركائنا. يخزن Google كلاً من المعلومات التي قدمتها للمحتوى
                +1 ومعلومات حول الصفحة التي شاهدتها عند النقر على + 1. يمكن أن يظهر رقم 1 الخاص بك كدليل إلى جانب اسم
                ملفك الشخصي وصورتك على خدمات Google، مثل نتائج البحث أو في ملفك الشخصي في Google أو في أي مكان آخر على
                مواقع الويب والإعلانات على الويب.</p>
            <p>تسجل Google معلومات حول نشاط 1+ لتحسين خدمات Google لك وللآخرين. لاستخدام زر Google، ستحتاج إلى ملف تعريف
                Google عام مرئي عالميًا يحتوي على الأقل على الاسم الذي تم اختياره للملف الشخصي. سيتم استخدام هذا الاسم
                في جميع خدمات Google. في بعض الحالات، قد يحل هذا الاسم أيضًا محل اسم آخر استخدمته عند مشاركة المحتوى عبر
                حساب Google الخاص بك. يمكن عرض هوية ملفك الشخصي في Google من قبل المستخدمين الذين يعرفون عنوان بريدك
                الإلكتروني أو لديهم معلومات تعريفية أخرى عنك.</p>
            <p>بالإضافة إلى الأغراض الموضحة أعلاه، سيتم استخدام المعلومات التي تقدمها وفقًا لسياسة خصوصية Google المعمول
                بها. يجوز لـ Google نشر إحصائيات ملخصة حول أنشطة المستخدمين +1 أو مشاركتها مع المستخدمين والشركاء، مثل
                الناشرين أو المعلنين أو مواقع الويب التابعة.</p>
            <p>لمزيد من المعلومات، راجع شروط خدمة Google وسياسة خصوصية Google. تلتزم Google بسياسة الخصوصية الخاصة بدرع
                الخصوصية بالولايات المتحدة، وهي مسجلة في برنامج Privacy Shield التابع لوزارة التجارة الأمريكية، وبالتالي
                توفر الضمانات المناسبة لمستوى مناسب من الخصوصية.</p>

            <h5 class="font-bold mt-5">أدوات التحليل والإعلان</h5>
            <h6 class="font-bold mt-5">متصفح الجوجل</h6>
            <p>لأغراض تحسين التسويق، نرسل، إذا لم يتم تعطيله من قبل المستخدم في إعدادات النظام بجهازه، معرّف إعلانات
                الجوال وعنوان البريد الإلكتروني المجزأ المقدم أثناء التسجيل والمعلمات المحددة في قسم "المكونات الإضافية
                الاجتماعية" (مثل عنوان IP، المتصفح والوصول إلى معلومات الجهاز) إلى Google Ireland Limited، Gordon House،
                Barrow Street، Dublin 4، Ireland، Parent company: Google LLC، 1600 Amphitheatre Parkway، Mountain View،
                California 94043 USA ("Google"). إذا كنت لا تريد منا جمع معرّف إعلانات الجوّال وإرساله إلى Google، يمكنك
                العثور على دليل لإلغاء التنشيط ضمن موضوع "معرّف إعلانات الجوّال". لقد أبرمنا ما يسمى "اتفاقية معالجة
                البيانات" مع Google، حيث نلتزم Google بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة.
                جوجل ادسنس
                يستخدم موقعنا الإلكتروني Google AdSense، وهي خدمة لدمج الإعلانات. المزود هو Google Ireland Limited،
                Gordon House، Barrow Street، Dublin 4، Ireland، الشركة الأم: Google LLC، 1600 Amphitheatre Parkway،
                Mountain View، California 94043 USA ("Google"). يستخدم Google AdSense ما يسمى "ملفات تعريف الارتباط"،
                وهي ملفات نصية يتم تخزينها على جهاز الكمبيوتر الخاص بك والتي تسمح بتحليل استخدام موقع الويب. يستخدم
                Google AdSense أيضًا ما يسمى بإشارات الويب (رسومات غير مرئية). يمكن استخدام إشارات الويب هذه لتقييم
                المعلومات مثل حركة الزوار على هذه الصفحات. يتم إرسال المعلومات التي تم إنشاؤها بواسطة ملفات تعريف
                الارتباط وإشارات الويب حول استخدام هذا الموقع (بما في ذلك عنوان IP الخاص بك) وتسليم تنسيقات الإعلانات
                إلى Google وتخزينها على خوادم في الولايات المتحدة الأمريكية. قد تشارك Google المعلومات التي تم إنشاؤها
                مع الشركات التابعة لها. ومع ذلك، لن تدمج Google عنوان IP الخاص بك مع البيانات الأخرى التي قمت بتخزينها.
                لدينا مصلحة مشروعة في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا. يمكنك منع
                تثبيت ملفات تعريف الارتباط عن طريق ضبط متصفحك وفقًا لذلك؛ ومع ذلك، نشير إلى أنه في هذه الحالة قد لا
                تتمكن من استخدام جميع ميزات هذا الموقع بشكل كامل.
                لقد أبرمنا ما يسمى بـ "اتفاقية معالجة البيانات" مع Google، حيث نلتزم Google بحماية بيانات عملائنا، وعدم
                الكشف عنها لأطراف ثالثة.
            </p>

            <h6 class="font-bold mt-5">Google AdWords و Google Conversion-Tracking</h6>
            <p>يستخدم هذا الموقع برنامج Google AdWords. AdWords هو برنامج إعلاني عبر الإنترنت لشركة Google Ireland
                Limited، Gordon House، Barrow Street، Dublin 4، Ireland الشركة الأم: Google LLC، 1600 Amphitheatre
                Parkway، Mountain View، California 94043 USA ("Google"). كجزء من Google AdWords، نستخدم ما يسمى تتبع
                التحويل. عند النقر فوق إعلان معروض بواسطة Google، يتم تعيين ملف تعريف ارتباط لتتبع التحويل. ملفات تعريف
                الارتباط هي ملفات نصية صغيرة يخزنها متصفح الإنترنت على كمبيوتر المستخدم. تفقد ملفات تعريف الارتباط هذه
                صلاحيتها بعد 30 يومًا ولا تُستخدم لتحديد الهوية الشخصية للمستخدمين. إذا قام المستخدم بزيارة صفحات معينة
                من هذا الموقع ولم تنتهي صلاحية ملف تعريف الارتباط بعد، فيمكننا أن ندرك أن Google قد نقر على الإعلان وتمت
                إعادة توجيهه إلى هذه الصفحة.
                يتلقى كل عميل من عملاء Google AdWords ملف تعريف ارتباط مختلفًا. لا يمكن تتبع ملفات تعريف الارتباط من
                خلال مواقع الويب الخاصة بالمعلنين. تُستخدم المعلومات التي يتم جمعها باستخدام ملف تعريف ارتباط التحويل
                لإنشاء إحصائيات تحويل لمعلني AdWords الذين اختاروا تتبع التحويل. يتم إخبار العملاء بالعدد الإجمالي
                للمستخدمين الذين نقروا على إعلانهم وتمت إعادة توجيههم إلى صفحة علامة تتبع التحويل. ومع ذلك، فهم لا
                يتلقون معلومات تحدد هوية المستخدمين شخصيًا. إذا كنت لا ترغب في المشاركة في التتبع، فيمكنك إلغاء الاشتراك
                في هذا الاستخدام عن طريق تعطيل ملف تعريف الارتباط لتتبع تحويل Google من متصفح الإنترنت الخاص بك ضمن
                تفضيلات المستخدم. لن يتم تضمينك في إحصاءات تتبع التحويل.
                يمكنك ضبط متصفحك بحيث يتم إخطارك بإعداد ملفات تعريف الارتباط وملفات تعريف الارتباط فقط على أساس كل حالة
                على حدة، أو قبول ملفات تعريف الارتباط لحالات معينة أو استبعاد ويمكن تنشيط الحذف التلقائي لملفات تعريف
                الارتباط عند إغلاق المتصفح. قد يؤدي تعطيل ملفات تعريف الارتباط إلى تقييد وظائف هذا الموقع.
                لدينا مصلحة مشروعة في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا.
                يمكن العثور على مزيد من المعلومات حول Google AdWords و Google Conversion Tracking في <a
                    href="https://policies.google.com/privacy">سياسة
                    خصوصية</a>
                Google. لقد أبرمنا ما يسمى بـ "اتفاقية معالجة البيانات" مع Google، حيث نلتزم Google بحماية بيانات
                عملائنا، وعدم الكشف عنها لأطراف ثالثة.
            </p>

            <h6 class="font-bold mt-5">تحليلات جوجل</h6>
            <p>تستخدم مواقعنا وظائف خدمة تحليلات الويب Google Analytics لغرض التصميم القائم على الاحتياجات والتحسين
                المستمر. المزود هو Google Ireland Limited، Gordon House، Barrow Street، Dublin 4، Irland، الشركة الأم:
                Google LLC، 1600 Amphitheatre Parkway، Mountain View، California 94043 USA ("Google"). يستخدم برنامج
                Google Analytics ما يسمى ب "ملفات تعريف الارتباط". هذه ملفات نصية يتم تخزينها على جهاز الكمبيوتر الخاص
                بك والتي تسمح بتحليل استخدامك للموقع. عادةً ما يتم نقل المعلومات التي تم إنشاؤها بواسطة ملف تعريف
                الارتباط حول استخدامك لهذا الموقع إلى خادم Google في الولايات المتحدة وتخزينها هناك.
                لدينا مصلحة مشروعة في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا.
            </p>
            <p>لقد أبرمنا ما يسمى بـ "اتفاقية معالجة البيانات" مع Google، حيث نلتزم Google بحماية بيانات عملائنا، وعدم
                الكشف عنها لأطراف ثالثة. فيما يلي التفاصيل المتعلقة بكيفية استخدام Google Analytics:</p>
            <ul>
                <li>إخفاء هوية IP: لقد قمنا بتنشيط وظيفة إخفاء هوية IP على هذا الموقع. ونتيجة لذلك، سيتم اقتطاع عنوان IP
                    الخاص بك بواسطة Google داخل الدول الأعضاء في الاتحاد الأوروبي أو الأطراف الأخرى في الاتفاقية الخاصة
                    بالمنطقة الاقتصادية الأوروبية قبل نقله إلى الولايات المتحدة. فقط في حالات استثنائية، سيتم إرسال
                    عنوان IP الكامل إلى خادم Google في الولايات المتحدة واختصاره هناك. نيابة عن مشغل موقع الويب هذا،
                    ستستخدم Google هذه المعلومات لتقييم استخدامك للموقع، ولتجميع تقارير عن نشاط موقع الويب ولتقديم خدمات
                    أخرى متعلقة بنشاط موقع الويب واستخدام الإنترنت لمشغل موقع الويب. لن يتم دمج عنوان IP المقدم من
                    Google Analytics كجزء من Google Analytics مع بيانات Google الأخرى.
                </li>
                <li>البرنامج المساعد للمتصفح: يمكنك منع تخزين ملفات تعريف الارتباط عن طريق ضبط برنامج المتصفح الخاص بك
                    وفقًا لذلك؛ ومع ذلك، نشير إلى أنه في هذه الحالة قد لا تتمكن من استخدام جميع ميزات هذا الموقع
                    بالكامل. بالإضافة إلى ذلك، يمكنك منع جمع Google للبيانات التي تم إنشاؤها بواسطة ملف تعريف الارتباط
                    والمتعلقة باستخدامك للموقع (بما في ذلك عنوان IP الخاص بك) وكذلك معالجة هذه البيانات بواسطة Google عن
                    طريق تنزيل وتثبيت البرنامج الإضافي للمتصفح متاح تحت هذا الرابط.
                </li>
                <li>معارضة جمع البيانات: بمساعدة هذه الوظيفة الإضافية للمتصفح لتعطيل Google Analytics JavaScript، يمكنك
                    منع Google Analytics من استخدام بياناتك أثناء الزيارات المستقبلية لهذا الموقع. لمزيد من المعلومات
                    حول كيفية التعامل مع بيانات المستخدم على Google Analytics، راجع سياسة خصوصية Google.
                </li>
                <li>معالجة البيانات المفوضة: لقد أبرمنا ما يسمى بـ "اتفاقية معالجة البيانات" مع Google، حيث نلزم Google
                    بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة.
                </li>
                <li>البيانات الديموغرافية: يستخدم موقعه على الويب ميزة "التركيبة السكانية" في Google Analytics. نتيجة
                    لذلك، يمكن إنتاج تقارير تحتوي على بيانات حول العمر والجنس واهتمامات زوار الموقع. تأتي هذه البيانات
                    من الإعلانات القائمة على الاهتمامات من Google وبيانات زوار الجهات الخارجية. لا يمكن تعيين هذه
                    البيانات لشخص معين. يمكنك تعطيل هذه الميزة في أي وقت من خلال إعدادات الإعلانات في حساب Google الخاص
                    بك، أو بشكل عام منع Google Analytics من جمع بياناتك كما هو موضح في قسم "معارضة جمع البيانات".
                </li>
            </ul>

            <h6 class="font-bold mt-5">تجديد النشاط التسويقي في Google Analytics</h6>
            <p>تستخدم مواقعنا الإلكترونية ميزات تجديد النشاط التسويقي في Google Analytics جنبًا إلى جنب مع الميزات عبر
                الأجهزة لبرنامج Google AdWords و Google DoubleClick. المزود هو Google Ireland Limited، Gordon House،
                Barrow Street، Dublin 4، Irland، الشركة الأم: Google LLC، 1600 Amphitheatre Parkway، Mountain View،
                California 94043 USA ("Google"). تسمح لك هذه الميزة بربط الجماهير الإعلانية التي تم إنشاؤها باستخدام
                تجديد النشاط التسويقي في Google Analytics بالإمكانيات عبر الأجهزة لكل من Google AdWords و Google
                DoubleClick. بهذه الطريقة، يمكن أيضًا عرض الرسائل الإعلانية المخصصة حسب الاهتمامات والتي تم تكييفها لك
                بناءً على استخدامك السابق وسلوك التصفح على أحد الأجهزة (مثل الهاتف المحمول) على جهاز آخر من أجهزتك (مثل
                الكمبيوتر اللوحي أو الكمبيوتر الشخصي).
                بمجرد منح موافقتك، ستقوم Google بربط سجل تصفح الويب والتطبيق بحساب Google الخاص بك لهذا الغرض. بهذه
                الطريقة، يمكن أن تظهر نفس الرسائل الإعلانية المخصصة على أي جهاز تقوم بتسجيل الدخول إليه باستخدام حساب
                Google الخاص بك. لدعم هذه الميزة، يجمع Google Analytics المعرفات المصدق عليها من Google للمستخدمين
                المرتبطين مؤقتًا ببيانات Google Analytics لتحديد وإنشاء الجماهير للترويج للإعلان عبر الأجهزة. يمكنك
                إلغاء الاشتراك نهائيًا في تجديد النشاط التسويقي / الاستهداف عبر الأجهزة عن طريق تعطيل الإعلانات المخصصة
                في حسابك على Google؛ للقيام بذلك اتبع هذا <a href="https://www.google.com/settings/ads/onweb">الرابط</a>.
            </p>
            <p>يعتمد تجميع البيانات التي تم جمعها في حساب Google الخاص بك فقط على موافقتك، والتي يمكنك منحها أو إبطالها
                على Google. لعمليات جمع البيانات التي لم يتم دمجها في حساب Google الخاص بك (على سبيل المثال، لأنك لا
                تملك حساب Google أو كنت قد اعترضت على الدمج).
                لمزيد من المعلومات وسياسة الخصوصية، راجع <a href="https://policies.google.com/technologies/ads">سياسة
                    خصوصية</a> Google. لقد أبرمنا ما يسمى "اتفاقية معالجة
                البيانات" مع Google، حيث نلتزم Google بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة.
            </p>

            <h6 class="font-bold mt-5">موقع التواصل الاجتماعي الفيسبوك</h6>
            <p>لأغراض تحسين التسويق، نرسل، إذا لم يتم تعطيله من قبل المستخدم في إعدادات النظام بجهازه، معرّف إعلانات
                الهاتف المحمول وعنوان البريد الإلكتروني المجزأ المقدم أثناء التسجيل والمعلمات المحددة في قسم "المكونات
                الإضافية الاجتماعية" (مثل عنوان IP، المتصفح والوصول إلى معلومات الجهاز) إلى Facebook Ireland Limited، 4
                Grand Canal Square، Dublin 2، Ireland، الشركة الأم: Facebook Inc، 1 Hacker Way، Menlo Park، CA 94025،
                USA ("Facebook"). تتم معالجة البيانات على أساس اهتماماتنا المشروعة بتحليل سلوك المستخدم وتحسين موقعنا
                على الويب وإعلاناتنا. إذا كنت لا تريد منا جمع معرّف إعلانات الهاتف المحمول وإرساله إلى Facebook، فيمكنك
                العثور على دليل لإلغاء التنشيط ضمن موضوع "معرّف إعلانات الجوّال".
                لقد أبرمنا اتفاقية خاصة مع Facebook ( "<a href="https://m.facebook.com/legal/controller_addendum?_rdr">ملحق
                    المراقب المالي</a>")، والتي تنظم بشكل خاص <a
                    href="https://m.facebook.com/legal/terms/data_security_terms?_rdr">إجراءات الأمان التي</a>
                يجب على Facebook الالتزام بها والتي وافق فيها Facebook على الوفاء بحقوق موضوع البيانات (أي يمكن
                للمستخدمين، على سبيل المثال، توفير معلومات أو طلبات حذف مباشرة إلى Facebook). ملاحظة: إذا زودنا Facebook
                بقيم وتحليلات وتقارير محسوبة (مجمعة، أي أنهم لا يتلقون أي معلومات حول المستخدمين الفرديين وهم مجهولون
                بالنسبة لنا)، فإن هذه المعالجة لا تتم في إطار المسؤولية المشتركة، ولكن على أساس ملحق معالجة البيانات (
                "<a href="https://www.facebook.com/legal/terms/dataprocessing?_rdr">شروط معالجة البيانات</a>" ")، " <a
                    href="https://www.facebook.com/legal/terms/data_security_terms?_rdr">شروط أمان البيانات </a>وفيما
                يتعلق بالمعالجة في الولايات المتحدة على أساس
                البنود التعاقدية القياسية ( "<a href="https://www.facebook.com/legal/EU_data_transfer_addendum?_rdr">ملحق
                    نقل البيانات بين Facebook والاتحاد الأوروبي</a>"). حقوق المستخدمين (خاصة
                المعلومات والحذف والاعتراض والشكوى إلى السلطة الإشرافية المختصة) غير مقيدة بالاتفاقيات مع Facebook.
                ستجد المزيد من المعلومات حول حماية خصوصيتك في <a href="https://www.facebook.com/about/privacy/?_rdr">سياسة
                    خصوصية</a> Facebook.
            </p>

            <h6 class="font-bold mt-5">فيسبوك بيكسل</h6>
            <p>
                يستخدم موقعنا بكسل إجراءات الزائر من Facebook Ireland Limited، 4 Grand Canal Square، Dublin 2، Ireland،
                الشركة الأم: Facebook Inc، 1 Hacker Way، Menlo Park، CA 94025، USA ("Facebook") لقياس التحويل. بهذه
                الطريقة، يمكن تتبع سلوك زوار الموقع بعد إعادة توجيههم إلى موقع الويب الخاص بالمزود من خلال النقر فوق
                إعلان Facebook. نتيجة لذلك، يمكن تقييم فعالية إعلانات Facebook للأغراض الإحصائية وأبحاث السوق وتحسين
                إجراءات الإعلان المستقبلية. البيانات التي تم جمعها مجهولة المصدر بالنسبة لنا كمشغل لهذا الموقع، لا
                يمكننا استخلاص استنتاجات حول هوية المستخدمين. ومع ذلك، يتم تخزين البيانات ومعالجتها بواسطة Facebook بحيث
                يكون الاتصال بملف تعريف المستخدم المعني ممكنًا ويمكن لـ Facebook استخدام البيانات لأغراض إعلانية خاصة
                به، وفقًا لتوجيه استخدام بيانات Facebook. نتيجة لذلك، يمكن لـ Facebook تمكين عرض الإعلانات على Facebook
                وخارج Facebook. لا يمكن أن يتأثر استخدام البيانات هذا من جانبنا بصفتنا مشغل الموقع.
                جنبًا إلى جنب مع Facebook Ireland Ltd.، نحن مسؤولون بشكل مشترك عن جمع أو استلام "بيانات الحدث" التي
                يجمعها Facebook (ولكن ليس مزيدًا من المعالجة) باستخدام عملية تسجيل الدخول الأحادي على Facebook والتي يتم
                تنفيذها على موقعنا. عرض عبر الإنترنت أو كجزء من نقل للأغراض التالية: أ) عرض معلومات الإعلان عن المحتوى
                التي تتوافق مع الاهتمامات المفترضة للمستخدمين؛ ب) تسليم الرسائل التجارية والمعاملات (مثل مخاطبة
                المستخدمين عبر Facebook Messenger)؛ ج) تحسين توصيل الإعلانات وإضفاء الطابع الشخصي على الوظائف والمحتوى
                (مثل تحسين التعرف على المحتوى أو المعلومات الإعلانية التي يفترض أنها تتوافق مع مصالح المستخدمين). لقد
                أبرمنا اتفاقية خاصة مع Facebook ("ملحق المراقب المالي")، والذي ينظم بشكل خاص إجراءات الأمان التي يجب على
                Facebook مراعاتها والتي وافق Facebook بموجبها على الوفاء بحقوق موضوع البيانات (أي يمكن للمستخدمين، على
                سبيل المثال، تقديم معلومات أو طلبات الحذف مباشرة إلى Facebook). ملاحظة: إذا زودنا Facebook بقيم وتحليلات
                وتقارير محسوبة (مجمعة، أي أنهم لا يتلقون أي معلومات حول المستخدمين الفرديين وهم مجهولون بالنسبة لنا)،
                فإن هذه المعالجة لا تتم في إطار المسؤولية المشتركة، ولكن على أساس ملحق معالجة البيانات ( "<a
                href="https://www.facebook.com/legal/terms/dataprocessing?_rdr">شروط معالجة البيانات</a>" ")، " <a
                href="https://www.facebook.com/legal/terms/data_security_terms?_rdr">شروط أمان البيانات</a> وفيما يتعلق
                بالمعالجة في الولايات المتحدة على أساس البنود التعاقدية
                القياسية ( "<a href="https://m.facebook.com/legal/terms/dataprocessing?_rdr">ملحق نقل البيانات بين
                Facebook والاتحاد الأوروبي</a>"). حقوق المستخدمين (خاصة المعلومات والحذف
                والاعتراض والشكوى إلى السلطة الإشرافية المختصة) غير مقيدة بالاتفاقيات مع Facebook.
                ستجد المزيد من المعلومات حول حماية خصوصيتك في <a href="https://www.facebook.com/about/privacy/">سياسة
                خصوصية</a> Facebook.
            </p>
            <p>يمكنك أيضًا تعطيل ميزة "الجماهير المخصصة" لتجديد النشاط التسويقي في قسم إعدادات الإعلانات على <a
                href="https://www.facebook.com/ads/preferences">الرابط</a>
                التالي. إذا لم يكن لديك حساب على Facebook، فيمكنك إلغاء الاشتراك في الإعلانات القائمة على الاستخدام من
                Facebook على موقع الويب الخاص بـ <a href="https://www.youronlinechoices.com/de/praferenzmanagement/">European
                    Interactive Digital Advertising Alliance</a>.
                لدينا مصلحة مشروعة في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا.
            </p>

            <h6 class="font-bold mt-5">هوتجار</h6>
            <p>نستخدم Hotjar، وهي خدمة تحليلات الويب لشركة Hotjar Ltd.، مركز أعمال سانت جوليانز، شارع Elia Zammit 3، St
                Julian's STJ 1000 (مالطا) ("Hotjar"). مع Hotjar، يتم تسجيل تفاعلات الزائرين الفرديين المختارين عشوائيًا
                لموقعنا على الويب بشكل مجهول. على سبيل المثال، يتم إنشاء سجلات حركات الماوس والنقرات من التسجيلات بهدف
                جعل مختصر أكثر سهولة وسهولة في الاستخدام. يستخدم Hotjar أيضًا "ملفات تعريف الارتباط"، وهي ملفات نصية
                مخزنة على جهاز الكمبيوتر الخاص بك. من أجل استبعاد علاقة شخصية، يتم تخزين عناوين IP بشكل مجهول فقط وتتم
                معالجة المعلومات بشكل مجهول فقط. كما يوفر معلومات، بما في ذلك معلومات حول نظام التشغيل الخاص بك |
                المستعرض | المنشأ الجغرافي (الدولة)، وتقييمها لأغراض إحصائية. هذه المعلومات ليست شخصية ولن يتم الكشف
                عنها لأطراف ثالثة بواسطتنا أو بواسطة Hotjar.
                إذا كنت لا تريد أن يتم تتبع بياناتك بواسطة Hotjar، فما عليك سوى اتباع هذا <a
                    href="https://www.hotjar.com/opt-out">الدليل</a>. لمزيد من المعلومات حول
                Hotjar، راجع Hotjar <a href="https://www.hotjar.com/privacy/">سياسة الخصوصية</a> و <a
                    href="https://www.hotjar.com/terms/">شروط
                    الاستخدام</a>.
                لقد دخلنا في ما يسمى بـ "اتفاقية معالجة البيانات" مع Hotjar، والتي نلزم Hotjar بموجبها بحماية بيانات
                عملائنا وعدم الكشف عنها لأطراف ثالثة.
            </p>

            <p>
            <h6 class="font-bold mt-5">بينتيريست</h6>
            <p>نحن نستخدم خدمات خدمة الرسائل القصيرة Pinterest. يتم تشغيل Pinterest بواسطة Pinterest Europe Limited،
                2nd Floor، Palmerston House، Fenian Street، Dublin 2، الشركة الأم: Pinterest Inc.، 651 Brannan Street،
                San Francisco، CA 94103 (USA) ("Pinterest"). يتيح لنا Pinterest استخدام الإعلانات القائمة على المجموعة
                المستهدفة وإعادة الاستهداف وقياسات التحويل للإعلان عبر الإنترنت عبر ما يسمى ببكسل تفاعل الزائر. هنا، يتم
                تنفيذ العروض لمجموعات مستهدفة محددة بناءً على مجموعة مختارة من المعايير العامة، مثل الخصائص الديموغرافية
                أو المناطق أو الاهتمامات. يتيح لنا Pinterest أيضًا استهداف الإعلانات استنادًا إلى مشاهدات الصفحة
                الأخيرة. على سبيل المثال، قد ترى إعلانات وإشعارات حول عروضنا ومحتوياتنا إذا كنت مهتمًا بخدمات أو معلومات
                أو عروض معينة في المعرض التجاري عبر الإنترنت. هنا يتم تقييم المعلومات العامة والتقنية فقط على الصفحات
                التي تم الوصول إليها. إذا كنت لا تريد عمومًا أن يتم تتبعك بواسطة Pinterest، فيمكنك منع تخزين ملفات تعريف
                الارتباط في أي وقت من خلال إعدادات المستعرض الخاص بك، مما قد يحد من الوظائف.
                لمزيد من المعلومات حول Pinterest، يرجى الرجوع إلى سياسة خصوصية Pinterest.
                لدينا مصلحة مشروعة في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا.</p>

            <h6 class="font-bold mt-5">إعلانات Snapchat</h6>
            <p> نستخدم خدمات خدمة الرسائل القصيرة Snapchat. يتم تشغيل إعلانات Snapchat بواسطة Snap Inc.، 63 Market
                Street، Venice، CA 90291 ("Snapchat"). لأغراض تحسين التسويق، نرسل، إذا لم يتم تعطيله من قبل المستخدم في
                إعدادات النظام بجهازه، معرّف إعلانات الهاتف المحمول وعنوان البريد الإلكتروني المجزأ المقدم أثناء التسجيل
                والمعلمات المحددة في قسم "المكونات الإضافية الاجتماعية" (مثل عنوان IP والمتصفح والوصول إلى معلومات
                الجهاز) إلى Snapchat. إذا كنت لا تريد منا جمع معرّف إعلانات الهاتف المحمول وإرساله إلى Snapchat، فيمكنك
                العثور على دليل لإلغاء التنشيط ضمن موضوع "معرّف إعلانات الجوّال". يُنشئ Snapchat pixel تجزئة من بيانات
                الاستخدام التي يتم إرسالها إلى Snapchat، مثل معلومات المتصفح. إذا كان لديك ملف تعريف Snapchat وقمت
                بتسجيل الدخول أو تسجيل الدخول إلى Snapchat من أحد أجهزتك، فيمكنك بعد ذلك استخدام البيانات التي توفرها
                وحدات البكسل لعرض الإعلانات الفردية لمنتجاتنا وعروضنا على أجهزتك عبر الأجهزة.
                لمزيد من المعلومات حول الغرض من جمع البيانات ونطاقها وكيفية معالجة Snapchat للبيانات واستخدامها،
                بالإضافة إلى إعدادات الخصوصية الخاصة بك، راجع سياسة خصوصية Snapchat. لقد أبرمنا ما يسمى بـ "اتفاقية
                معالجة البيانات" مع Snapchat، حيث نلزم Snapchat بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة
                والامتثال لأحكام البنود التعاقدية القياسية وفقًا للفن.. 46 القانون العام لحماية البيانات (GDPR) في حالة
                نقل البيانات الشخصية إلى الولايات المتحدة الأمريكية.
                لدينا مصلحة مشروعة في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا.</p>
            <h6 class="font-bold mt-5">إعلانات تويتر</h6>
            <p> نحن نستخدم خدمات خدمة الرسائل القصيرة تويتر. يتم تشغيل إعلانات Twitter بواسطة Twitter Inc.، 1355 Market
                Street، Suite 900، San Francisco، CA 94103 (الولايات المتحدة الأمريكية) بالإضافة إلى شركتها الفرعية
                Twitter International Company، One Cumberland Place، Fenian Street، Dublin، Ireland (معًا "Twitter").
                يتيح لنا Twitter استخدام الإعلان المستند إلى المجموعة المستهدفة وإعادة الاستهداف وقياسات التحويل للإعلان
                عبر الإنترنت عبر ما يسمى ببكسل تفاعل الزائر. هنا، يتم تنفيذ العروض لمجموعات مستهدفة محددة بناءً على
                مجموعة مختارة من المعايير العامة، مثل الخصائص الديموغرافية أو المناطق أو الاهتمامات. يسمح لنا Twitter
                أيضًا باستهداف الإعلانات بناءً على مشاهدات الصفحة الأخيرة. على سبيل المثال، قد ترى إعلانات وإشعارات حول
                عروضنا ومحتوياتنا إذا كنت مهتمًا بخدمات أو معلومات أو عروض معينة في المعرض التجاري عبر الإنترنت. هنا يتم
                تقييم المعلومات العامة والتقنية فقط على الصفحات التي تم الوصول إليها. إذا كنت لا تريد أن يتتبعك تويتر
                بشكل عام، فيمكنك منع تخزين ملفات تعريف الارتباط في أي وقت من خلال إعدادات المتصفح، مما قد يحد من
                الوظائف. لأغراض تحسين التسويق، نرسل، إذا لم يتم تعطيله من قبل المستخدم في إعدادات النظام بجهازه، معرّف
                إعلانات الجوّال وعنوان البريد الإلكتروني المُجزَّأ المُعطى أثناء التسجيل والمعلمات المحددة في قسم
                "المكونات الإضافية الاجتماعية" (مثل عنوان IP والمتصفح والوصول إلى معلومات الجهاز) إلى Twitter. إذا كنت
                لا تريد منا جمع معرّف إعلانات الجوال وإرساله إلى Twitter، فيمكنك العثور على دليل لإلغاء التنشيط ضمن
                موضوع "معرّف إعلانات الجوال".
                يلتزم Twitter أيضًا بإعداد عدم التتبع في متصفحه. بصفتك مستخدم Twitter، يمكنك أيضًا منع معالجة البيانات
                الموضحة عن طريق تعطيل مربع الاختيار "تخصيص الإعلانات استنادًا إلى معلومات الشركة التابعة" بجوار العنوان
                "المحتوى المدعوم" في إعدادات الأمان والخصوصية. يمكن العثور على مزيد من المعلومات على صفحات Twitter.
                لقد أبرمنا ما يسمى بـ "اتفاقية معالجة البيانات" مع Twitter، حيث نلتزم Twitter بحماية بيانات عملائنا،
                وعدم الكشف عنها لأطراف ثالثة والامتثال لأحكام البنود التعاقدية القياسية وفقًا للفن. لدينا مصلحة مشروعة
                في تحليل سلوك المستخدم من أجل تحسين كل من موقعنا الإلكتروني وإعلاناتنا.</p>
            <h6 class="font-bold mt-5">ميل تشيمب / ماندريل</h6>
            <p>يستخدم هذا الموقع خدمات MailChimp لإرسال الرسائل الإخبارية (أو mandrill لإرسال المعلومات ذات الصلة
                بالمستخدم). المزود هو Rocket Science Group LLC، 675 Ponce De Leon Ave NE، Suite 5000، Atlanta، GA 30308
                (الولايات المتحدة الأمريكية) ("MailChimp").</p>
            <p>MailChimp هي خدمة يمكن من خلالها، من بين أمور أخرى، تنظيم إرسال الرسائل الإخبارية وتحليلها. إذا قمت
                بإدخال البيانات لغرض الاشتراك في الرسائل الإخبارية (مثل عنوان البريد الإلكتروني)، فسيتم تخزينها على
                خوادم MailChimp في الولايات المتحدة الأمريكية.</p>
            <p>بمساعدة MailChimp يمكننا تحليل حملات الرسائل الإخبارية الخاصة بنا. عندما تفتح بريدًا إلكترونيًا تم
                إرساله باستخدام MailChimp، فإن ملفًا مضمنًا في البريد الإلكتروني (يسمى إشارة الويب) يتصل بخوادم
                MailChimp في الولايات المتحدة. بهذه الطريقة يمكنك تحديد ما إذا تم فتح رسالة إخبارية والروابط التي تم
                النقر عليها. بالإضافة إلى ذلك، يتم جمع المعلومات الفنية (مثل وقت الاسترداد وعنوان IP ونوع المتصفح ونظام
                التشغيل). يمكن تعيين هذه المعلومات إلى مستلم الرسالة الإخبارية المعني. يتم استخدامها حصريًا للتحليل
                الإحصائي لحملات الرسائل الإخبارية. يمكن استخدام نتائج هذه التحليلات لتصميم النشرات الإخبارية المستقبلية
                بشكل أفضل وفقًا لمصالح المستلم.</p>
            <p>إذا كنت لا تريد أن يتم تحليلك بواسطة MailChimp، فيجب عليك إلغاء الاشتراك في النشرة الإخبارية. لهذا
                الغرض، نقدم رابطًا في كل رسالة إخبارية. علاوة على ذلك، يمكنك إلغاء الاشتراك من النشرة الإخبارية مباشرة
                على الموقع.</p>
            <p>تتم معالجة البيانات على أساس تسجيل النشرة الإخبارية النشطة الخاصة بك ومصالحنا المشروعة لتحسين محتوى
                رسائلنا الإخبارية وفقًا لمصالح متلقينا. يمكنك أن تتعارض مع هذا في أي وقت عن طريق إلغاء الاشتراك من
                النشرة الإخبارية.</p>
            <p>البيانات المودعة لدينا لغرض الحصول على النشرة الإخبارية سيتم حفظها من قبلنا من النشرة الإخبارية حتى
                تستلمها وسيتم حذفها من خوادمنا وكذلك من خوادم MailChimp بعد إلغاء النشرة الإخبارية.</p>
            <p>للحصول على التفاصيل، راجع سياسة خصوصية Mailchimp. لقد أبرمنا ما يسمى "اتفاقية معالجة البيانات" مع
                MailChimp، حيث نلتزم MailChimp بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة والامتثال لأحكام
                البنود التعاقدية القياسية وفقًا لـ Art. 46 القانون العام لحماية البيانات (GDPR) في حالة نقل البيانات
                الشخصية إلى الولايات المتحدة الأمريكية.</p>
            <h6 class="font-bold mt-5">معلومات الدفع</h6>
            <p>عندما تقوم بالدفع مقابل خدماتنا، لا يتم تخزين معلومات بطاقة الائتمان أو الخصم على خوادمنا. يتم تخزين هذه
                المعلومات من قبل شركات معالجة الدفع المتوافقة مع PCI التابعة لجهات خارجية. نحن نعمل مع مقدمي الخدمات
                التاليين:</p>
            <ul>
                <li>Google play store</li>
                <li>iTunes</li>
                <li>Tap payment, 29 Khalid Ibn Al Waleed St, Kuwait City, Kuwait</li>
            </ul>
            <p>تحدث جميع معاملات بطاقات الائتمان والخصم بين الكمبيوتر الذي نشأت منه المعاملة ومعالج الدفع الخاص بنا.
                عندما
                تستخدم إحدى مراحل التجربة أو الاشتراكات الخاصة بنا أو تشتري شيئًا من خلال الخدمة، يتم جمع معلومات بطاقة
                الائتمان والمعلومات المالية الأخرى التي نحتاجها لمعالجة الدفع وتخزينها لدى مزود خدمة الدفع. نقوم أيضًا
                بجمع
                بعض المعلومات المحدودة، مثل رقم هاتفك المحمول وتفاصيل سجل معاملاتك. بالإضافة إلى ذلك، عادةً ما يزودنا
                موفرو
                خدمة الدفع هؤلاء بمعلومات محدودة للغاية عنك، مثل "الرمز المميز" الفريد، والذي يمكّنك من إجراء المزيد من
                عمليات الشراء باستخدام البيانات المخزنة بواسطة مزودي الخدمة، بالإضافة إلى نوع بطاقتك وتاريخ انتهاء
                الصلاحية.
                وآخر أربعة أرقام من الرقم.</p>
            <p>لقد أبرمنا ما يسمى "اتفاقيات معالجة البيانات" المنفصلة مع متجر google play ومتجر التطبيقات و Tap payment
                حيث
                نلتزم بمتجر google play ومتجر التطبيقات و Tap payment لحماية بيانات عملائنا، حتى لا نكشف عنها. لأطراف
                ثالثة.</p>

            <h6 class="font-bold mt-5">طلب المبالغ المستردة</h6>
            <p>إذا اشتريت اشتراكًا عبر iTunes واعتبرت أنه تم خصم دفعة لأحد منتجاتنا بشكل خاطئ من حسابك، فيمكنك، كمستخدم
                iOS، طلب استرداد الأموال مباشرة من خلال حساب المستخدم الخاص بك داخل تطبيق iOS، والذي سيكون بعد ذلك تمت
                معالجتها مباشرة من قبل شركة Apple (Apple Inc.، One Apple Park Way، CA Cupertino 95014، USA، "Apple") من
                خلال
                Appstore. من أجل تمكين Apple من اتخاذ قرار بشأن شرعية طلب استرداد الأموال الخاص بك، يجوز لنا نقل بيانات
                حول
                سجل الاستخدام الخاص بك إلى Apple. أنت توافق على نقل البيانات هذا عن طريق طلب استرداد الأموال في
                حسابك.</p>
            <h5 class="font-bold mt-5">الاستضافة والتحليل</h5>
            <h6 class="font-bold mt-5">خدمات أمازون ويب</h6>
            <p>نستخدم الخدمات التالية من Amazon Web Services، Inc، Montgomery Street 420، San Francisco CA 94163، USA
                ("Amazon Web Services" أو "AWS"):</p>
            <h6 class="font-bold mt-5">مركز بيانات AWS</h6>
            <p>تساعدنا خدمة AWS هذه على استضافة تطبيقاتنا الخلفية. يخزن AWS Data Center بيانات المستخدم مثل البريد
                الإلكتروني والعنوان والاسم الأول والاسم الأخير وبيانات التفاعل. تتم معالجة البيانات على أساس اهتماماتنا
                المشروعة (المادة 6 (1) المضاءة واللائحة العامة لحماية البيانات) لتوفير خدماتنا الخالية من الأخطاء الفنية
                والمحسّنة.</p>
            <h6 class="font-bold mt-5">AWS التحديد</h6>
            <p>تُستخدم خدمة AWS هذه لتقييم بيانات تفاعل الهاتف المحمول. تقوم AWS بتخزين بيانات المستخدم مثل معرف مختصر
                مجهول الاسم ورقم تعريف الجهاز وبيانات التفاعل. تتم معالجة البيانات على أساس اهتماماتنا المشروعة (المادة
                6
                (1) المضاءة واللائحة العامة لحماية البيانات) لتحليل سلوك المستخدم وتحسين موقعنا على الإنترنت.
                لمزيد من المعلومات حول معالجة البيانات بواسطة Amazon Web Services، يرجى الرجوع إلى سياسة خصوصية Amazon
                Web
                Services و<a href="https://aws.amazon.com/privacy/">سياسة خصوصية</a> Amazon الأساسية. لقد أبرمنا ما يسمى
                "اتفاقية معالجة البيانات" مع AWS، حيث نلزم
                AWS
                بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة والامتثال لأحكام البنود التعاقدية القياسية وفقًا
                للفن..
                46 القانون العام لحماية البيانات (GDPR) في حالة نقل البيانات الشخصية إلى الولايات المتحدة الأمريكية.</p>
            <h6 class="font-bold mt-5">منصة جوجل السحابية</h6>
            <p>نستخدم خدمات Google Cloud Platform، وهي عبارة عن نظام أساسي للمطورين تديره شركة Google Ireland Limited،
                Gordon House، Barrow Street، Dublin 4، Irland، الشركة الأم: Google LLC، 1600 Amphitheatre Parkway،
                Mountain
                View، California 94043 USA ("Google") للعثور على الأخطاء في تطبيق مختصر باستخدام خدمة Firebase
                Crashlytics.
                تتم معالجة البيانات على أساس مصالحنا المشروعة لتوفير خدماتنا الخالية من الأخطاء الفنية والمحسّنة. يتلقى
                Google Cloud Platform / Firebase Crashlytics بيانات المستخدم لتحليل الأخطاء مثل معرّفات إعلانات الجوّال
                و
                UUID للتثبيت (معرّف فريد عالميًا) ومعرّفات Android وعناوين IP.</p>
            <p>لمزيد من المعلومات حول معالجة بيانات Firebase Crashlytics عبر Google Cloud Platform، راجع <a
                href="https://policies.google.com/privacy">سياسة خصوصية Google</a>. لقد أبرمنا ما يسمى بـ "اتفاقية
                معالجة البيانات" مع Google، مشغل Google Cloud Platform وخدمة
                Firebase
                Crashlytics، حيث نلزم Google بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة.</p>

            <h6 class="font-bold mt-5">Microsoft AppCenter</h6>
            <p>نحن نستخدم Microsoft AppCenter، وهي خدمة تابعة لشركة Microsoft Corporation، One Microsoft Way، Redmond،
                WA،
                الولايات المتحدة الأمريكية ("Microsoft") لتخزين وتحليل تقارير الأعطال الخاصة بخدمتنا. لهذا الغرض، يتلقى
                Microsoft AppCenter منا تقارير الأعطال ومعرفات المستخدم المجهولة. تتم معالجة البيانات على أساس
                اهتماماتنا
                المشروعة (المادة 6 (1) المضاءة و اللائحة العامة لحماية البيانات) لتوفير خدماتنا الخالية من الأخطاء
                الفنية
                والمحسّنة.</p>
            <p> لمزيد من المعلومات حول معالجة بيانات Microsoft، راجع <a
                href="https://privacy.microsoft.com/en-us/privacystatement">سياسة خصوصية</a> Microsoft. لقد أبرمنا ما
                يسمى بـ "اتفاقية معالجة البيانات" مع Microsoft، والتي نلتزم بموجبها Microsoft بحماية بيانات عملائنا،
                وعدم الكشف عنها لأطراف ثالثة.</p>

            <h6 class="font-bold mt-5">موقع يوتيوب</h6>
            <p>على موقعنا وبناءً على اهتماماتنا المشروعة بموجب الفن. 6 الفقرة. 1 جملة 1 مضاءة. f GDPR لعرض محتوى
                الفيديو، نستخدم مكونات (مقاطع فيديو) لشركة YouTube، LLC 901 Cherry Ave.، 94066 San Bruno، CA،
                ("YouTube")، وهي شركة تابعة لشركة Google Ireland Limited، Gordon House، Barrow Street، Dublin 4،
                Ireland، الشركة الأم: Google LLC، 1600 Amphitheatre Parkway، Mountain View، California 94043 USA
                ("Google"). هنا نستخدم خيار "وضع الخصوصية الممتد" الذي يوفره موقع YouTube.</p>
            <p>عندما تزور صفحة تحتوي على مقطع فيديو مضمن، فإنها ستتصل بخوادم YouTube وتعرض المحتوى عن طريق إخطار متصفحك
                على
                موقع الويب.</p>
            <p>وفقًا للمعلومات المقدمة من YouTube، في "وضع الخصوصية الممتد"، يتم نقل بياناتك فقط - على وجه الخصوص، أي
                مواقع الويب التي قمت بزيارتها والمعلومات الخاصة بالجهاز بما في ذلك عنوان IP - إلى خادم YouTube في
                الولايات المتحدة عندما تشاهدها الفيديو. بالضغط على الفيديو فإنك تؤكد هذا الإرسال. إذا كنت لا تريد
                الإرسال، فتوقف عن تشغيل الفيديو.</p>
            <p>إذا قمت بتسجيل الدخول إلى YouTube في نفس الوقت، فسيتم إقران هذه المعلومات بحساب عضويتك على YouTube. يمكنك
                منع هذا عن طريق تسجيل الخروج من حساب عضويتك قبل زيارة موقعنا على شبكة الإنترنت. لمزيد من المعلومات حول
                حماية البيانات المتعلقة بـ YouTube، يرجى الاطلاع على <a
                    href="https://policies.google.com/privacy?hl=en">سياسة خصوصية</a> Google. لقد أبرمنا ما يسمى
                "اتفاقية
                معالجة البيانات" مع Google، حيث نلتزم Google بحماية بيانات عملائنا، وعدم الكشف عنها لأطراف ثالثة.</p>

            <p class="font-bold">في حال كان لديك أي استفسار يرجى التواصل عبر البريد الإلكتروني:
                <a href="mailto:info@muktasar.com">info@muktasar.com</a></p>

        </div>
    </div>
</template>
<script>

export default {
    data() {
    },
}
</script>
<style>
a {
    text-decoration: none !important;
}
</style>